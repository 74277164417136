import { Button, Container, Grid, Paper, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";

const Notfound = () => {
  return (
    <>
      <Container
        maxWidth="sm"
        style={{ backgroundColor: "#f5f5f5", borderRadius: "10px" }}
      >
        <Typography
          variant="h2"
          style={{ textAlign: "center", padding: "10px" }}
        >
          404 not found
        </Typography>
      </Container>
      <Container maxWidth="sm" style={{marginTop: '50px'}} >
        <Grid container spacing={10} style={{justifyContent: 'space-around'}} > 
            <Grid item>
                <Paper elevation='10'>
                    <Button size='large'>Home</Button>
                </Paper>
            </Grid>
            <Grid item>
                <Paper elevation='10'>
                    <Button size='large' >Back</Button>
                </Paper>
            </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Notfound;
