import React from 'react'
import {TextField, Grid, InputAdornment, IconButton, Avatar, Paper, Typography} from '@material-ui/core'
import useStyles from '../styles'
import EditIcon from '@mui/icons-material/Edit'
import EditOff from '@mui/icons-material/EditOff'
import DoneIcon from '@mui/icons-material/Done'

const ProfileSettings = ({user, newuser, doneFunction, editFunction, editingName, setNewUser }) => {
    const classes = useStyles();
    
    return (
        <Paper elevation={10} className={classes.paperItmes}>
                            <Avatar alt={user.result.name} src={user.result.imageUrl}>{user.result.name.charAt(0)}</Avatar>
                            {editingName ? (
                                <>
                                    <TextField name="name" variant="filled" autoFocus value={newuser.name} onChange={(e) => setNewUser({... newuser, name: e.target.value})} classname={classes.inputfields} size='small' hiddenLabel />
                                    <IconButton onClick={doneFunction}>
                                        <DoneIcon />
                                    </IconButton>
                                </>
                            ): (
                                <>
                                    <Typography style={{marginInline: '20px'}}>{user.result.name}</Typography>
                                    {!user.result.googleId ? (
                                        <IconButton onClick={editFunction} >
                                            <EditIcon />
                                        </IconButton>
                                    ): (
                                        <IconButton disabled >
                                            <EditOff />
                                        </IconButton>
                                    )}
                                    
                            </>
                            )}
                    </Paper>
    )
}

export default ProfileSettings
