import React, {useState, useEffect} from 'react';
import {AppBar, Avatar, Button, Typography, Toolbar, Menu, MenuItem, Box, IconButton, Divider} from '@material-ui/core';
import { Link, useHistory, useLocation } from 'react-router-dom'
import useStyles from './styles';
import {useDispatch} from 'react-redux'
import decode from 'jwt-decode'
import MenuIcon from '@material-ui/icons/Menu'
import AccountCircle from '@material-ui/icons/AccountCircle'
import LogoutIcon from '@mui/icons-material/Logout'
import SettingsIcon from '@mui/icons-material/Settings'
import { useSelector } from 'react-redux';
const Navbar = () => {
    const classes = useStyles();
    
    
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')))
    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const authData = useSelector((state) => state);
    //console.log(authData)
    //console.log(location.pathname)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
     };

     const handleClose = () => {
        setAnchorEl(null);
      };
    

    const logout = () => {
        dispatch({ type: 'LOGOUT'})
        history.push('/auth')
        setUser(null);
        handleClose();
    }

    useEffect(() => {
        const token = user?.token;
        if(token ){
            
            const decodedToken = decode(token);

            if(decodedToken.exp * 1000 < new Date().getTime()) logout();
        }

        setUser(JSON.parse(localStorage.getItem('profile')));
    }, [location, authData])


    return (
            <AppBar position="static" className={classes.appBar} style={{borderRadius: '0px'}} >
                <div className={classes.brandContainer}>
                    <Typography className={classes.heading} component={Link} to="/" varitant="h2" align="center">DiaWeb</Typography>
                </div>
                <Toolbar>
                    {user ? (
                        <div className={classes.profile}>
                        <Avatar className={classes.purple} alt={user.result.name} src={user.result.imageUrl}>{user.result.name.charAt(0)}</Avatar>
                        <Typography className={classes.userName} variant="h6">{user.result.name}</Typography>
                        <IconButton  size="large" edge="start" color="black" aria-label="menu" sx={{ mr: 2 }} onClick={handleClick}>
                            <MenuIcon />
                        </IconButton>
                        <Menu anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{'aria-labelledby': 'basic-button', vertical: "bottom"}} getContentAnchorEl={null} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "center" }}>
                            <MenuItem onClick={handleClose}><AccountCircle/> Profile</MenuItem>
                            <MenuItem component={Link} to={'/settings'} onClick={handleClose}><SettingsIcon/> Settings</MenuItem>
                            <Divider />
                            <MenuItem onClick={logout}><LogoutIcon/>Logout</MenuItem>
                        </Menu>
                    </div>
                    ): (
                        <Button component={Link} to="/auth" variant="contained" style={{color: '#5196CC', backgroundColor: '#fcfcfc', fontWeight: 'bold'}}>Sign in</Button>
                        
                    )}
                </Toolbar>
            </AppBar>
    )
}



export default Navbar