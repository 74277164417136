import React, {useEffect} from 'react';
import useStyles from './styles';
import { Card, CardActions, CardContent, CardMedia, Button, Typography, Paper, Divider, Link, Grid, Container} from '@material-ui/core';
import {useDispatch} from 'react-redux';
import Item from './items/item';
const Meal = ({ meal, setCurrentID }) => {
    const dispatch = useDispatch();
    //const classes  = useStyles();
    const user = JSON.parse(localStorage.getItem('profile'));
    
    useEffect(() => {
        //setCurrentID(meal.id);
    });
    
    return (
        <div style={{width: '100%'}}>
            <Paper elevation={10} style={{background: 'linear-gradient(99.39deg, rgba(255, 255, 255, 0.4) 20.14%, rgba(255, 255, 255, 0.1) 67.44%)', backdropFilter: 'blur(5px)', borderRadius: '40px', alignContent: 'center', width: '365px', height
        : '255px'}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
                <div>
                    <Typography style={{color: '#20518D', fontSize: '5ch', opacity: '0.5', fontWeight: '550'}} >{meal.name}</Typography>
                    
                </div>
                <Divider style={{marginLeft: '4.5%', marginRight: '4.5%', width: '100%', background: 'linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(0,0,1,1) 50%, rgba(0,212,255,0) 100%)'}} ></Divider>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', height: '70%', justifyContent: 'space-between'}}>
                <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%'}}>  
                    <Grid container alignItems='stretch' spacing={3} style={{width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '5px'}}>
                        {meal.items.slice(0, 4).map(item => {
                            return(
                                <Grid item xs={12} sm={6} md={4} lg={5}>
                                    <Item item={item?.item}/>
                                </Grid>
                            )
                        })}
                    </Grid>
                </div>
                
                <div style={{padding: '5px'}}>
                        <Divider style={{bottom: '10px'}}/>
                        <Typography style={{fontWeight: '700', fontSize: '20px'}} >Carbs: {(meal.totalCarbs*100).toFixed(2)}%</Typography>
                </div>
            </div>
                
            </Paper>
        </div>
    )
}

export default Meal;