import { makeStyles } from '@material-ui/core/styles';
import { height } from '@mui/system';

export default makeStyles((theme) => ({
  appBar: {
    borderRadius: 15,
    margin: '30px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  heading: {
    color: 'rgba(0,183,255, 1)',
  },

  welcomemsg: {
    marginTop: '3%',
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '40px',
    lineHeight: '50px',
    textAlign: 'center',
    color: '#20518D',
    mixBlendMode: 'normal',
    opacity: '0.5'
  },

  submsg: {
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '25px',
    textAlign: 'center',
    marginTop: '3%'
  },

  image: {
    marginLeft: '15px',
  },
  [theme.breakpoints.down('sm')]: {
    mainContainer: {
      flexdirection: "column-reverse"
    }
}
}));


