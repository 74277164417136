import React, {useState, useEffect} from 'react';
import { Container, AppBar, Typography, Grow, Grid, Button, Paper, Divider} from '@material-ui/core';
import {Link} from 'react-router-dom'
import Posts from '../Posts/Posts';
import Form from '../Form/Form';
import { useDispatch } from 'react-redux';
import {getPosts} from '../../actions/posts';
import useStyles from '../../styles';
import Shortcuts from './Shortcuts/shortcuts';
import { fontSize } from '@mui/system';
import Food from '../foodSection/food';
const Home = () => {
    const [currentID, setCurrentID] = useState(null);
    const classes  = useStyles();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')))

    useEffect(() => {
        dispatch(getPosts());
        setUser(JSON.parse(localStorage.getItem('profile')))
    }, [dispatch, currentID]);
    return (
        <Grow in>
                {user? (
                    <Container maxWidth='xl' style={{alignItems: 'center', flexDirection: 'column', display: 'flex'}}>
                    <Food />
                </Container>
                ): (
                    <Container maxWidth='md' style={{alignItems: 'center', flexDirection: 'column', display: 'flex'}}>
                        <Paper elevation={10} style={{width: '720px', height: '226px', background: 'linear-gradient(99.39deg, rgba(255, 255, 255, 0.4) 20.14%, rgba(255, 255, 255, 0.1) 67.44%)', backdropFilter: 'blur(1px)', borderRadius: '40px', marginTop: '10%', alignContent: 'center'}}>
                            <Typography className={classes.welcomemsg} >PERSONAL DIABETES LOGBOOK</Typography>
                            <Divider style={{marginLeft: '4.5%', marginRight: '4.5%', width: '-webkit-fill-available', background: 'linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(0,0,1,1) 50%, rgba(0,212,255,0) 100%)'}} ></Divider>
                            <Typography className={classes.submsg} >You’re very own personal website to make life with diabetes esier</Typography>
                            <div style={{flexDirection: 'column', alignItems: 'center', display: 'flex'}}> 
                                <Button component={Link} to="/auth" variant="contained" style={{color: '#5196CC', backgroundColor: '#fcfcfc', fontWeight: 'bold', marginTop: '3%', fontSize: '15px', paddingLeft: '50px', paddingRight: '50px'}}>Sign in</Button>
                            </div>
                        </Paper>
                    </Container>
                )}
                
            </Grow>
    )
}

export default Home

