import dotenv from 'dotenv';
import axios from 'axios';
dotenv.config();
let axiosDefaults = require('axios/lib/defaults');
axiosDefaults.baseURL = 'https://api.danimtech.com/';
const API = axios.create({baseURL: 'https://api.danimtech.com/'})


API.interceptors.request.use((req) => {
    if(localStorage.getItem('profile')) {
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`
    }

    return req;
})

export const fetchPosts = () => API.get('/posts');
export const createPost = (newPost) =>API.post('/posts', newPost); 
export const updatePost = (id, updatedPost) => API.patch(`/posts/${id}`, updatedPost);
export const deletePost = (id) => API.delete(`/posts/${id}`);
export const likePost = (id) => API.patch(`/posts/${id}/likePost`);

export const signIn = (formData) => API.post('/user/signin', formData);
export const signUp = (formData) => API.post('/user/signup', formData);
export const updateUser = (user) => API.patch('/user/update', user);

export const getItems = () => API.get('/items');
export const createItem = (item) => API.post('/items', item);
export const searchItems = (query) => API.get(`/items/search/${query}`);
export const deleteItem = (id) => API.delete(`/items/${id}`);

export const getMeals = (meal) => API.get('/meals', meal);
export const createMeal = (meal) => API.post('/meals', meal);
export const searchMeals = (query) => API.get(`/meals/search/${query}`);
