import { Typography, Divider, TextField, InputAdornment, Button, IconButton, auto, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup, Autocomplete } from '@mui/material'
import React, {useState, useEffect} from 'react'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete'; 
import { getMeals } from '../../actions/meal';
import {useSelector} from 'react-redux';
import { useDispatch } from 'react-redux';
import { height, padding } from '@mui/system';
import { useHistory, Redirect } from 'react-router';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
function Index() {
    const [alignment, setAlignment] = React.useState('2');
    const [alignmenttwo, setAlignmenttwo] = React.useState('1');
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const [bg, setBg] = useState(0);
    const [other, setOther] = useState(0);
    const mealTemplate = {name: '', carbs: 0, amount: 0}
    const [selectedMeals, setSelectedMeals] = useState([mealTemplate]);
    const meals = useSelector((state) => state.meals);
    const dispatch = useDispatch();
    const [corrDose, setCorrDose] = useState(0);
    const [totDose, setTotDose] = useState(0);
    const [totalGram, setTotalGram] = useState(0);
    const [correctionFactorio, setCorrectionFactorio] = useState(0);
    const [mealQuota, setMealQuota] = useState(0);

    let history = useHistory();
    useEffect(() => {
        if(!user){
            history.push('/auth');
        }
        dispatch(getMeals());
    }, [])

    const handleAlignment = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
          }
      };

      const handleNewItem = (value, index, name) => {
        let newSelectedIMeals = [...selectedMeals];
        
        if(name == 'carbs'){
            newSelectedIMeals[index].name = value
            value = (meals.find((meall) => meall.name == value));
            
            if(value){value = parseFloat(value.totalCarbs)}
            console.log(value);
        }
        
        newSelectedIMeals[index][name] = parseFloat(value);
        setSelectedMeals(newSelectedIMeals);
        console.log(newSelectedIMeals)
    }

      const handleAlignmentTwo = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignmenttwo(newAlignment);
          }
      };

      const handleDeleteMeal = (index) => {
        let newSelectedMeals = [...selectedMeals];
        newSelectedMeals.splice(index,1);
        setSelectedMeals(newSelectedMeals);
    }
    
    useEffect(() => {
        let factor = 0;
        let fac = 0;
        switch(alignment){
            case '1':
                factor = user?.result?.options?.correctionMorningQuota;
                fac = user?.result?.options?.morningQuota;
                break;
            case'2':
                factor = user?.result?.options?.correctionDayQuota;
                fac = user?.result?.options?.dayQuota;
            case '3':
                factor = user?.result?.options?.correctionDayQuota;
                fac = user?.result?.options?.evningQuota;
        }
        
        setMealQuota(fac);
        setCorrectionFactorio(factor);
        setCorrDose(((bg-6)/factor).toFixed(2));
        TotalGram();
        totalDosage();
    })

    const correctionFactor = () => {
        console.log(user.result.options.correctionMorningQuota)
        if(alignment == '1'){
            return parseFloat(user.result.options.correctionMorningQuota);
        }else if (alignment == '2'){
            return parseFloat(3);
        }

    }
    
    const totalDosage = () => {
        let total = 0;
        if(totalGram > 0){
            total = totalGram/mealQuota;
        }
        
        if(corrDose > 0 && alignmenttwo == '1'){
            total += parseFloat(corrDose);
        }
        setTotDose(total.toFixed(2));
        
    }



    const TotalGram = () => {
        let total = 0;
        selectedMeals.map((meal) => {
            if(meal.amount > 0 ){
                total+= meal.amount*meal.carbs.toFixed(2);
            }
        })
        if(other){total+= parseFloat(other);}

        
        setTotalGram(total);
    }


  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px', rowGap: '20px', height: '90%'}} >
        <div style={{display: 'grid', justifyContent: 'center' }} >
            <Typography variant="h4" style={{marginTop: '20px', opacity: '0.7'}}><b>Bolus Calculator</b></Typography>
            <Divider style={{marginLeft: '4.5%', marginRight: '4.5%', width: '100%', background: 'linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(0,0,1,1) 50%, rgba(0,212,255,0) 100%)'}} ></Divider>
        </div>
        <div style={{background: 'linear-gradient(99.39deg, rgba(255, 255, 255, 0.4) 20.14%, rgba(255, 255, 255, 0.1) 67.44%)', backdropFilter: 'blur(5px)', borderRadius: '40px', alignContent: 'center', padding: '30px', width: '90%', height: '100%', rowGap: '30px', display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', columnGap: '30px'}} >
                
                <div style={{display: 'flex', flexDirection: 'column', rowGap:'10px'}}>
                    <Typography style={{fontSize: '25px'}}><b>Blood Glucose</b></Typography>
                    <TextField type={"number"} onChange={(e) => setBg(e.target.value)} variant='outlined' InputProps={{endAdornment: (<InputAdornment position="end">mmol/l</InputAdornment>)}} ></TextField>
                    { parseInt(user?.result?.options.maxBg) < parseInt(bg) && <div>
                        <Typography style={{color: 'red'}} >{`${bg} mmol/l is above your max of ${user.result.options.maxBg} mmol/l`}</Typography>
                        <Typography >Add an Correction bolus of {corrDose} units?</Typography>
                        <ToggleButtonGroup exclusive value={alignmenttwo} onChange={handleAlignmentTwo} style={{columnGap: '10px'}} >
                        <ToggleButton style={{border: '0', padding: 0}} value="1" ><Button style={{}} variant='contained'variant={alignmenttwo==1? 'contained': 'outlined'} color='primary' >Yes</Button></ToggleButton>
                        <ToggleButton style={{border: '0', padding: 0}} value="2" ><Button style={{}} variant={alignmenttwo==2? 'contained': 'outlined'} color='primary' >No</Button></ToggleButton>
                         </ToggleButtonGroup>
                        </div>}
                    <Typography style={{fontSize: '25px'}}><b>Time of day</b></Typography>
                    <ToggleButtonGroup exclusive value={alignment} onChange={handleAlignment} style={{columnGap: '10px'}} >
                        <ToggleButton style={{border: '0', padding: 0}} value="1" ><Button style={{}} variant='contained'variant={alignment==1? 'contained': 'outlined'} color='primary' >Morning</Button></ToggleButton>
                        <ToggleButton style={{border: '0', padding: 0}} value="2" ><Button style={{}} variant={alignment==2? 'contained': 'outlined'} color='primary' >Day</Button></ToggleButton>
                        <ToggleButton style={{border: '0', padding: 0}} value="3" ><Button style={{}} variant={alignment==3? 'contained': 'outlined'} color='primary' >Evening</Button></ToggleButton>
                    </ToggleButtonGroup>
                    

                </div>
                <Divider orientation='vertical' ></Divider>
                <div>
                    <div>
                    <Typography style={{fontSize: '25px'}}><b>INFO</b></Typography>
                    </div>
                    <Typography>This is a <b>bolus</b> calculator. Enter all the details as accurate as possible. Carefully check the calculations to make sure no typo nor any miss calcualtions have been done.</Typography>
                </div>
                <Divider orientation='vertical' ></Divider>
                <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                    <Typography style={{fontSize: '25px'}} ><b>Select Meals</b></Typography>
                    <div style={{height: '20vh', overflowY: 'scroll'}} >
                    {selectedMeals.map((item, index) => (
                            
                            <div style={{display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center'}} >
                            {selectedMeals.length > 1 &&  <IconButton onClick={() => handleDeleteMeal(index)} ><DeleteIcon /></IconButton>}
                        
                            <Autocomplete onChange={(event, newValue) => handleNewItem(newValue, index, 'carbs')} disablePortal options={meals.map((item) => item.name)} renderInput={(params) => <TextField {...params} style={{width: '200px'}} label="Meal" />}/>
                            <TextField type={"number"} onChange={(e) => handleNewItem(e.target.value, index, 'amount')} style={{width: '80px'}} label="amount" InputProps={{endAdornment: <InputAdornment position='start' >g</InputAdornment>}}/>
                            {index == selectedMeals.length - 1 && <IconButton onClick={() => {setSelectedMeals([...selectedMeals, mealTemplate])}}><AddIcon /></IconButton>}
                            </div>
                        ))}
                    </div>
                    <div>
                    <Typography style={{fontSize: '25px'}}><b>Other carbs</b></Typography>
                    <TextField type={"number"} onChange={(e) => setOther(e.target.value)} variant='outlined' InputProps={{endAdornment: (<InputAdornment position="end">gram</InputAdornment>)}} ></TextField>
                    </div>
                </div>
            </div>
            <Divider style={{marginLeft: '4.5%', marginRight: '4.5%', width: '90%', background: 'linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(0,0,1,1) 50%, rgba(0,212,255,0) 100%)'}} ></Divider>
            <div>
                <Typography style={{fontSize: '25px'}}>Calculations for total Insulin: <b>{totDose} units</b></Typography>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between'}} >
                    <TableContainer component={Paper}style={{width: '550px'}}>
                        <Table  size='small' >
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>Meals (calculations)</b></TableCell>
                                    <TableCell align="right" ><b>Carbs (%)</b></TableCell>
                                    <TableCell align="right" ><b>Weight (g)</b></TableCell>
                                    <TableCell align="right" ><b>Total Carbs (g)</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {selectedMeals.map((meal, index) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                        <TableCell component={"th"} scope='row' >{meal.name}</TableCell>
                                        <TableCell align='right' >{meal.carbs.toFixed(2)*100}</TableCell>
                                        <TableCell align='right' >{meal.amount}</TableCell>
                                        <TableCell align='right' >{meal.amount*meal.carbs.toFixed(2)}</TableCell>
                                    </TableRow>
                            ))}
                            {other >0 && (<TableRow>
                                <TableCell component={"th"} scope='row' >Other</TableCell>
                                        <TableCell align='right' >100</TableCell>
                                        <TableCell align='right' >{other}</TableCell>
                                        <TableCell align='right' >{other}</TableCell>
                            </TableRow>)}

                            
                            
                            </TableBody>
                            
                            <TableRow style={{padding: '300px'}} >
                                <TableCell><b>Total Carbs: {totalGram} </b></TableCell>
                            </TableRow>
                        </Table>
                    </ TableContainer>
                    <div style={{display: 'flex', flexDirection: 'column', height: '100%', alignItems: 'row'}}>
                    <ArrowForwardIcon style={{fontSize: '50px'}}/>
                    </div>
                    <TableContainer component={Paper}style={{width: '550px'}}>
                        <Table  size='small' >
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>Dosage (calculations)</b></TableCell>
                                    <TableCell align="right" ><b>Quota </b></TableCell>
                                    <TableCell align="right" ><b>Dosage (units)</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {totalGram > 0 && (<TableRow key={"meals"} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                        <TableCell component={"th"} scope='row' >{totalGram} g carbs</TableCell>
                                        <TableCell align='right' >{mealQuota}</TableCell>
                                        <TableCell align='right' >{(totalGram/mealQuota).toFixed(2)}</TableCell>
                                    </TableRow>)}
                                    {(corrDose > 0 && alignmenttwo == '1') && (<TableRow key={"meals"} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                        <TableCell component={"th"} scope='row' >{bg-6} diff (mmol/l)</TableCell>
                                        <TableCell align='right' >{correctionFactorio}</TableCell>
                                        <TableCell align='right' >{(corrDose)}</TableCell>
                                    </TableRow>)}
                            </TableBody>
                            
                            <TableRow style={{padding: '300px'}} >
                                <TableCell><b>Total: {totDose} </b></TableCell>
                            </TableRow>
                        </Table>
                    </ TableContainer>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Index