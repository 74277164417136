import {GET_MEALS, CREATE_MEAL} from '../constants/actionTypes';
import * as api from '../api';


// Action Creators
export const getMeals = () => async (dispatch) => {

    try {
        const {data} = await api.getMeals();
        dispatch({type: GET_MEALS, payload: data});
    } catch (error) {
        console.log(error.message);
    }   
}

export const createMeal = (meal) => async (dispatch) => {
    try {
        const req = await api.createMeal(meal);
        dispatch({ type: CREATE_MEAL, payload: req.data})
        return req;
    } catch (error) {
        //console.log(error.response.data.message)
        return error;
    }
}

export const searchMeals = (query) => async (dispatch) => {
    try {
        const {data} = await api.searchMeals(query);
        dispatch({ type: GET_MEALS, payload: data});
    } catch (error) {
        console.log(error.message);
    }
}