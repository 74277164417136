export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const FETCH_ALL = 'FETCH_ALL';
export const LIKE = 'LIKE'


export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';

export const USER_UPDATE = 'USER_UPDATE'

export const CREATE_ITEM = 'CREATE_ITEM'
export const GET_ITEMS = 'GET_ITEMS'
export const DELETE_ITEM = 'DELETE_ITEM'

export const CREATE_MEAL = 'CREATE_MEALS'
export const GET_MEALS = 'GET_MEAL'
