import React, {useState, useEffect} from 'react';
import { Container, AppBar, Typography, Grow, Grid} from '@material-ui/core';
import './App.css'
import Home from './components/Home/Home'
import Items from './components/itemSection/items';

import { BrowserRouter, Switch, Route} from 'react-router-dom'

import Navbar from './components/Navbar/Navbar';
import Auth from './components/Auth/Auth';
import Settings from './components/Settings/Settings'
import Notfound from './components/Notfound/Notfound';
import Sidebar from './components/sidebar/Sidebar';
import Calculator from './components/calculator/index'

const App = () => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    

    return (
            <div style={{backgroundImage: 'url("https://2020presidentsreport.arcadia.edu/wp-content/uploads/2020/04/hd-pattern-png-transparent-triangle-transparent-png-image-pattern-transparent-2048_1024.png")', height: '100vh', width:'100%', backgroundSize: 'cover', overflow: 'hidden'}}>
               
            <BrowserRouter style>
            <Navbar />
            <div style={{display: 'flex', height: '100%'}}>
                <Sidebar /> 
                
                    <Container maxWidth="xl" style={{height: '90%', overflowY: 'scroll'}}>
                        
                        <Switch>
                            <Route path="/" exact component={Home} />
                            <Route path="/auth" exact component={Auth} />
                            <Route path="/settings" exact component={Settings} />
                            <Route path="/items" exact component={Items} />
                            <Route path="/calculator" exact component={Calculator} />
                            <Route component={Notfound} />
                        </Switch>
                    </Container>
                </div>
            </BrowserRouter>
            </div>
    )
}

export default App;