import {AUTH} from '../constants/actionTypes';
import * as api from '../api';

export const updateUser = (user) => async (dispatch) => {
    try {
        console.log(user)
        const {data} = await api.updateUser(user);
        dispatch({type: AUTH, data})
    } catch (error) {
        console.log(error.message);
    }
}