import React, {useEffect} from 'react';
import useStyles from './styles';
import { Card, CardActions, CardContent, CardMedia, Button, Typography, Paper, Divider, Link} from '@material-ui/core';
import {useDispatch} from 'react-redux';
const Item = ({item}) => {
    const dispatch = useDispatch();
    //const classes  = useStyles();
    const user = JSON.parse(localStorage.getItem('profile'));

    useEffect(() => {
        console.log(item)
    }, [])  
    
    
    
    
    return (
        <div style={{backgroundColor: 'white', borderRadius: '20px', boxShadow: 'inset 0px 4px 4px 0px rgba(0,0,0,0.2)', display: 'flex', justifyContent: 'space-around' ,alignItems: 'center', flexDirection: 'row', padding: '3px'}} >
            <Typography variant='h6' style={{fontWeight: '600', opacity: '0.8', fontSize: '15px'}} >{item?.name}</Typography>
            <Divider style={{marginTop: '4px', marginBottom: '4px'}} orientation='vertical' flexItem />
            <Typography  >{(item?.carbs*100).toFixed(2)}%</Typography>
        </div>
    )
}

export default Item;