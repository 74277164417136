import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  paperItmes: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    justifyContent: 'space-between',
  },
  header: {
    marginBottom: '10px',
    fontSize: '30px',
  },
  inputfields: {
      height: '1px',
  },
  smMargin: {
    margin: theme.spacing(1),
  },
}));