import React from "react";
import {
  InputAdornment,
  IconButton,
  Paper,
  Typography,
  Divider,
} from "@material-ui/core";
import useStyles from "../styles";
import DoneIcon from "@mui/icons-material/Done";
import { TextField } from "@mui/material";

const OptionsSettings = ({
  newuser,
  user,
  setNewUser,
  doneFunction,
  description,
  label,
  option,
  Addorment,
}) => {
  const classes = useStyles();

  return (
    <Paper elevation={10} className={classes.paperItmes}>
      <div style={{ marginTop: "10px" }}>
        <TextField
          color={
            newuser.options[option] != user.result.options?.[option] &&
            "warning"
          }
          type="number"
          label={label}
          variant="outlined"
          value={newuser.options[option]}
          onChange={(e) =>
            setNewUser({
              ...newuser,
              options: { ...newuser.options, [option]: e.target.value },
            })
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{Addorment}</InputAdornment>
            ),
          }}
          size="small"
          style={{ maxWidth: "150px" }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {newuser.options[option] != user.result.options?.[option] ? (
          <IconButton onClick={doneFunction} style={{ marginLeft: "20px" }}>
            <DoneIcon />
          </IconButton>
        ) : (
          <>
          </>
        )}
        <Divider style={{ marginBlock: "10px" }} />
        <Typography
          variant="subtitle2"
          style={{ color: "#5c5c5c" }}
          style={{ maxWidth: "230px" }}
          color='#646464'
        >
          {description}
        </Typography>
      </div>
    </Paper>
  );
};

export default OptionsSettings;
