import React, { useState } from 'react'
import {TextField, Grid, InputAdornment, IconButton, Avatar, Paper, Typography, Button, Snackbar, Divider} from '@material-ui/core'
import { Alert } from '@mui/material'
import useStyles from '../styles'
import EditIcon from '@mui/icons-material/Edit'
import EditOff from '@mui/icons-material/EditOff'
import DoneIcon from '@mui/icons-material/Done'
import { display } from '@mui/system'

const ProfileSettings = ({newuser, setNewUser, doneFunction }) => {
    const classes = useStyles();
    const [changing, setChangin] = useState(false);
    const [tempPass, setTempPass] = useState('');
    const [temoConfPass, setConfPass] = useState('');
    const [errorStatus, setErrorStatus] = useState('')
    const [open, setOpen] = useState(false)
    
    const checkMatch = () => {

        if(tempPass == ''){
            setErrorStatus("New password can't be empty")
            setOpen(true)
            return
        }

        if(tempPass != temoConfPass){
            setErrorStatus("Passwords dosen't match")
            setOpen(true);
            return
        }

        doneFunction();
        setChangin(false);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

      
    return (
        <Paper elevation={10} className={classes.paperItmes}>
                            {changing ? (
                                <>
                                    <div style={{display: 'flex', flexDirection: 'row'}} >
                                        <TextField style={{marginRight: '10px'}} label='New password'name="name" variant="standard" autoFocus value={tempPass} onChange={(e) => setTempPass(e.target.value)} classname={classes.inputfields} size='small' />
                                        <TextField style={{marginRight: '10px'}} label="Confirm password" variant="standard" autoFocus value={temoConfPass} onChange={(e) => setConfPass(e.target.value)} classname={classes.inputfields} size='small' />
                                        <Button style={{marginRight: '10px'}} variant="outlined" onClick={() => {checkMatch()}}>Confirm</Button>
                                    </div>
                                    
                                </>
                            ): (
                                <>
                                    <Button variant="outlined" onClick={() => setChangin(true)}>Change password</Button>
                                    
                            </>
                            )}
                             <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                                    {errorStatus}
                                </Alert>
                            </Snackbar>

                    </Paper>
    )
}

export default ProfileSettings
