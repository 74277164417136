import React, {useEffect, useState} from 'react';
import {Container, Paper, Typography, Grid, Avatar, IconButton, InputAdornment, Snackbar, Divider} from '@material-ui/core'
import { Alert, TextField } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Redirect } from 'react-router';
import useStyles from './styles';

import EditIcon from '@mui/icons-material/Edit'
import EditOff from '@mui/icons-material/EditOff'
import DoneIcon from '@mui/icons-material/Done'
import { updateUser } from '../../actions/user';
import { signin } from '../../actions/auth';
import ProfileSettings from './SettingsComponents/profileSettings'
import OptionsSettings from './SettingsComponents/OptionsSettings';
import PasswordSettings from './SettingsComponents/PasswordSettings'
const Settings = () => {
    const posts = useSelector((state) => state.posts);
    const dispatch = useDispatch();
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const [newuser, setNewUser] = useState(JSON.parse(localStorage.getItem('profile'))?.result);
    const [editingName, setEditingName] = useState(false);
    const [succShow, setSuccShow] = useState(false);
    const history = useHistory();
    const classes = useStyles();
    const authData = useSelector((state) => state);
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('profile')));
    }, [authData])
    console.log(user);

    const token = user?.token;
    if(!token){
        return(<Redirect to="/auth"></Redirect>)
    }
    
    const editNameHandle = () => setEditingName((prev) => !prev)
    const successResOpen = () => setSuccShow((prev) => !prev)
    

    const handleClick = () => {
      setOpen(true);
    };
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };
    const HandleEdit = () => {
        dispatch(updateUser(newuser));
        setUser(JSON.parse(localStorage.getItem('profile')));
        handleClick();
    }

    

    return (
        <div>
        <Container maxWidth='md' >
            <Typography variant="h6" className={classes.header}>Profile details</Typography>
            <Divider style={{marginBottom: '30px'}}/>
            <Grid container spacing={5} style={{ backgroundColor: '#f5f5f5', padding: '20px', borderRadius: '20px'}} >
                <Grid item xs='auto'>
                    <ProfileSettings user={user} newuser={newuser} doneFunction={() => {editNameHandle(); HandleEdit();}} editFunction={() => {editNameHandle();}} editingName={editingName} setNewUser={setNewUser} />
                </Grid>

                <Grid item xs='auto'>
                    <PasswordSettings newuser={newuser} setNewUser={setNewUser} doneFunction={HandleEdit}/>
                </Grid>
            </Grid>
            
            <Typography variant="h6" className={classes.header} style={{margin: "20px 0px"}}>Glucose threshold</Typography>
            <Divider style={{marginBottom: '40px'}}/>
            <Grid container spacing={5} style={{ backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '20px'}}     >
                <Grid item xs='auto' style={{}}>
                    <Paper elevation={10} className={classes.paperItmes}>
                        <div style={{marginTop: '10px'}}>
                            <TextField color={newuser?.options?.maxBg != user.result?.options?.maxBg && 'warning'} type='number' label="Max target"variant="outlined" value={newuser.options?.maxBg} onChange={(e) => setNewUser({...newuser, options: {...newuser.options, maxBg: e.target.value}})} InputProps={{endAdornment: <InputAdornment position="end">mmol/L</InputAdornment>}} size='small' style={{maxWidth: '150px'}}></TextField>
                            {newuser.options?.maxBg != user.result.options?.maxBg ? (
                                <IconButton onClick={HandleEdit} style={{marginLeft: '20px'}}>
                                    <DoneIcon />
                                </IconButton>
                            ): (
                                <>
                                </>
                            )}
                            
                            <Divider style={{marginBlock: '10px'}}/>
                            <Typography variant="subtitle2" style={{color: '#5c5c5c'}} style={{maxWidth: '230px'}}>Maximum blood glucos target (less than 15 mm/L)</Typography> 
                        </div>
                    </Paper>
                </Grid>
                
                <Grid item xs='auto'>
                    <Paper elevation={10} className={classes.paperItmes}>
                        <div style={{marginTop: '10px'}}>
                            <TextField color={newuser.options.minBg != user.result.options?.minBg && 'warning'} type='number' label="Min target" variant="outlined" value={newuser.options?.minBg} onChange={(e) => setNewUser({...newuser, options: {...newuser.options, minBg: e.target.value}})} InputProps={{endAdornment: <InputAdornment position="end">mmol/L</InputAdornment>}} size='small' style={{maxWidth: '150px'}}></TextField>
                            {newuser.options.minBg != user.result.options?.minBg ? (
                                <IconButton onClick={HandleEdit} style={{marginLeft: '20px'}}>
                                    <DoneIcon />
                                </IconButton>
                            ): (
                                <>
                                </>
                            )}
                            <Divider style={{marginBlock: '10px'}}/>
                            <Typography variant="subtitle2" style={{color: '#5c5c5c'}} style={{maxWidth: '230px'}}>Minimum blood glucos target (more than 3.1 mm/L)</Typography> 
                        </div>
                    </Paper>
                </Grid>
            </Grid>
           
            <Typography variant="h6" className={classes.header} style={{margin: "20px 0px"}}>Glucose quota</Typography>
            <Divider style={{marginBottom: '40px'}}/>
            <Grid container spacing={5} style={{ backgroundColor: '#f5f5f5', padding: '20px', borderRadius: '20px'}} >
                <Grid item xs='auto'>
                    <OptionsSettings Addorment='gram/Unit' label="Morning Quota" description="The quota used for the insulin dose in the morning (Units of insulin per gram of carbs)" option={'morningQuota'} newuser={newuser} user={user} setNewUser={setNewUser} doneFunction={() => {HandleEdit();}} />
                </Grid>
                <Grid item xs='auto'>
                    <OptionsSettings Addorment='gram/Unit' label="Day Quota" description="The quota used for the insulin dose during the day (Units of insulin per gram of carbs)" option={'dayQuota'} newuser={newuser} user={user} setNewUser={setNewUser} doneFunction={() => {HandleEdit();}} />
                </Grid>
                <Grid item xs='auto'>
                    <OptionsSettings Addorment='gram/Unit' label="Evning Quota" description="The quota used for the insulin in the evning (Units of insulin per gram of carbs)" option={'evningQuota'} newuser={newuser} user={user} setNewUser={setNewUser} doneFunction={() => {HandleEdit();}} />
                </Grid>
                <Grid item xs='auto'>
                    <OptionsSettings Addorment='mmol/L' label="Correction (morning)" description="The quota for how many mmol/L 1 unit lowers in the Morning" option={'correctionMorningQuota'} newuser={newuser} user={user} setNewUser={setNewUser} doneFunction={() => {HandleEdit();}} />
                </Grid>
                <Grid item xs='auto'>
                    <OptionsSettings Addorment='mmol/L' label="Correction (day)" description="The quota for how many mmol/L 1 unit lowers during the day" option={'correctionDayQuota'} newuser={newuser} user={user} setNewUser={setNewUser} doneFunction={() => {HandleEdit();}} />
                </Grid>
                
            </Grid>
                
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Successfully saved setting!
                </Alert>
            </Snackbar>
            
        </ Container >
        </div>
    )
}

export default Settings;