import { makeStyles } from '@material-ui/core/styles';
import { deepPurple } from '@material-ui/core/colors';

export default makeStyles((theme) => ({
  appBar: {
    borderRadius: '0px 0px 20px 20px',
    margin: '0px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 50px',
    backgroundColor: '#1D5091',
    mixBlendMode: 'normal',
    opacity: '0.8',
    zIndex: theme.zIndex.drawer + 1,
    height: '70px',
    //backgroundColor: 'white'
  },
  heading: {
    fontSize: '40px',
    color: 'white',
    textDecoration: 'none',
    fontWeight: 'bolder'
  },
  image: {
    marginLeft: '15px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '400px',
  },
  profile: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '300px',
    padding: '0px 0px',
  },
  userName: {
    display: 'flex',
    alignItems: 'center',
    color: 'black'
  },
  brandContainer: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center'
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
}));