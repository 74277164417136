import React, {useState, useEffect} from 'react';
import {AppBar, Avatar, Button, Typography, Toolbar, Menu, MenuItem, Box, IconButton, Divider, Drawer} from '@material-ui/core';
import { Link, useHistory, useLocation } from 'react-router-dom'
import useStyles from './styles';
import {useDispatch} from 'react-redux'
import RestaurantIcon from '@mui/icons-material/Restaurant';
import KitchenIcon from '@mui/icons-material/Kitchen';
import CalculateIcon from '@mui/icons-material/Calculate';
import { useSelector } from 'react-redux';
const Navbar = () => {
    
    
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')))
    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const authData = useSelector((state) => state);



    return (
            <Drawer anchor='left' variant='permanent' style={{zIndex: 1,position: 'inherit', width: '100px', height: '100vh'}} PaperProps={{style: {position: 'inherit', backgroundColor: 'rgba(9, 55, 115, 0.8)'}}} >
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '10px'}}>
                <Divider style={{marginLeft: '4.5%', marginRight: '4.5%', width: '-webkit-fill-available', background: 'linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(0,0,1,1) 50%, rgba(0,212,255,0) 100%)'}} ></Divider>
                    <Button onClick={()=> history.push('/')} >
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <RestaurantIcon style={{color: 'black'}} fontSize='large' />
                            <Typography>Meals</Typography>
                            
                        </div>
                    </Button>
                    <Divider style={{marginLeft: '4.5%', marginRight: '4.5%', width: '-webkit-fill-available', background: 'linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(0,0,1,1) 50%, rgba(0,212,255,0) 100%)'}} ></Divider>
                    <Button onClick={() => history.push('/items')} >
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <KitchenIcon style={{color: 'black'}} fontSize='large' />
                            <Typography>Items</Typography>
                            
                        </div>
                    </Button>
                    <Divider style={{marginLeft: '4.5%', marginRight: '4.5%', width: '-webkit-fill-available', background: 'linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(0,0,1,1) 50%, rgba(0,212,255,0) 100%)'}} ></Divider>
                    <Button onClick={() => history.push('/calculator')} >
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <CalculateIcon style={{color: 'black'}} fontSize='large' />
                            <Typography style={{fontSize: '14px'}} >Calculator</Typography>
                            
                        </div>
                    </Button>
                </div>
            </Drawer>
    )
}



export default Navbar