import { Accordion, Container, AccordionSummary, AccordionDetails, Typography, Button, CircularProgress, IconButton, Backdrop, Paper, TextField, InputAdornment} from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {getItems, searchItems} from '../../actions/item';
import SearchField from './components/SearchField'
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import zIndex from '@material-ui/core/styles/zIndex';
import {createItem, deleteItem} from '../../actions/item';
import {useHistory} from 'react-router';

function Items() {

    const items = useSelector(state => state.items);
    const [size, setSize] = useState(10);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const initialState = {name: '', carbs: '', description: ''};
    const [newItem, setNewItem] = useState(initialState);
    let dispatch = useDispatch();

    let history = useHistory();
    useEffect(() => {
        dispatch(getItems());
        
        
    }, []);
    const handleSearchChange = (e) => {
        e.target.value.length > 0 ? dispatch(searchItems(e.target.value)) : dispatch(getItems());
    }
    
    const handleChange = (e) => {
        setNewItem({...newItem, [e.target.name]: e.target.value});
    }

    const handleSubmit = (e) => {
        setLoading(true);
        console.log('item created');
        dispatch(createItem({...newItem, carbs: newItem.carbs/100})).then(() => {
            
            setNewItem(initialState);
            setLoading(false);
            setOpen(false);
        });
    }

    const handleDelete = (id) => {
        dispatch(deleteItem(id));
    }


    return (
        <div>

            
            <Container maxWidth='sm' style={{marginTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '20px'}}>
                <Backdrop style={{zIndex: 1}}open={open}>
                    <Paper style={{width: '', height: '',padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '10px'}}>
                        <Typography><b>New Item</b></Typography>
                        <div>
                            <TextField onChange={handleChange} style={{maxWidth: '180px'}} variant='standard' name='name' label='Name'/>
                        </div>
                        <div>
                            <TextField onChange={handleChange} style={{maxWidth: '180px'}} variant='standard' name='carbs' label='Carbs' InputProps={{endAdornment: <InputAdornment position='start' >%</InputAdornment>}}/>
                        </div>
                        <div>
                            <TextField onChange={handleChange} style={{maxWidth: '180px'}} multiline variant='standard' name='description' label='Description'/>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', columnGap: '10px'}} >
                            <Button onClick={handleSubmit} variant='outlined' >Finnish</Button>
                            <Button onClick={()=> setOpen(false)} >Cancel</Button>
                        </div>
                    </Paper>
                </Backdrop>
                <div >
                    <SearchField onChangee={handleSearchChange}/>
                </div>
                {!items.length ? <Typography >No Items found!</Typography> : (
                <div style={{width: '75%'}}>
                <div style={{overflowY: 'scroll', maxHeight: '70vh'}} >
                    {items.slice(0, size).map(item => {return(
                        <Accordion key={item._id}>
                            <AccordionSummary>
                                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                        <Typography style={{flexShrink: 0 }}>{item.name}</Typography>
                                        <Typography style={{ color: 'text.secondary', float: 'right' }}  >{(item.carbs*100).toFixed(2)}% Carbs</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails >
                                <div  style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                <Typography>{item.description}</Typography>
                                <IconButton name={item._id} onClick={(e) => handleDelete(item._id)} ><DeleteIcon /></IconButton>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    )})}
                </div>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', flexDirection: 'column'}}>
                    <Typography style={{textAlign: 'center', marginTop: '20px'}}>Showing {size} of {items.length} items</Typography>
                    <Button type='' onClick={() => {setSize(size+10)}}>Load More</Button>
                </div>
        </div>
                )}
                
                <Button onClick={() => {setOpen(!open)}} style={{position: 'fixed', bottom: '20px', right: '20px', borderRadius: '20px', backgroundColor: 'white'}} variant='contained' >Add Item<AddCircleIcon style={{marginLeft: '5px'}} /></Button>
            </Container>
        </div>
    )
}

export default Items
