import { combineReducers } from 'redux';
import posts from './posts'
import auth from './auth'
import items from './items'
import meals from './meals'

export default combineReducers({
    posts,
    auth,
    items,
    meals
});