import React from 'react';
import { Card, CardActions, CardContent, CardMedia, Button, Typography, Paper, Divider, Link, TextField, Input } from '@material-ui/core';
import {useDispatch} from 'react-redux';
const SearchField = ({ onChangee }) => {
    const dispatch = useDispatch();
    //const classes  = useStyles();
    const user = JSON.parse(localStorage.getItem('profile'));
    
    return (
        <Paper elevation={10} style={{padding: '10px', borderRadius: '40px', width: '400px', display: 'flex', flexDirection: 'column', alignItems: 'center',  backgroundColor: 'rgba(9, 55, 115, 0)', backdropFilter: 'blur(10px)'}} >
            <TextField onChange={(e) => onChangee(e)} placeholder='Search for meals' style={{width: '90%'}} />
        </Paper>
    )
}

export default SearchField;