import React, {useState, useEffect} from 'react';
import useStyles from './styles';
import { TextField, Button, Typography, Paper, CircularProgress, Grid, IconButton, Container, Divider, Backdrop, InputAdornment} from '@material-ui/core';
import FileBase from 'react-file-base64';
import { useDispatch } from 'react-redux';
import { createPost, updatePost } from '../../actions/posts';
import {useSelector} from 'react-redux';
import { createItem, getItems } from '../../actions/item';
import { createMeal } from '../../actions/meal';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { getMeals, searchMeals } from '../../actions/meal';
import Meal from './meals/meal';
import SearchField from './components/SearchField';
import { Autocomplete } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const Food = ({currentID, setCurrentID, setLoading, loading}) => {
    const [postData, setPostData] = useState({ title: '', message: '', tags: '', selectedFile: ''});
    
    //const post = useSelector((state) => currentID? state.posts.find((p) => p._id == currentID): null);
    const meals = useSelector((state) => state.meals);
    const Items = useSelector((state) => state.items);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const [apiReponse, setApiResponse] = useState('');
    const user = JSON.parse(localStorage.getItem('profile'));
    const testsampleItem = {item: {}, amount: 0};
    const [newMeal, setNewMeal] = useState({name: '', description: '', items: [], totalWeight: '', totalCarbs: ''});
    //create a useState that will hold the selected items from the list
    const [selectedItems, setSelectedItems] = useState([testsampleItem]);
    const [TotalCarbs, setTotalCarbs] = useState('0');
    
useEffect(() => {
    dispatch(getItems());
}, [])
useEffect(() => {
    calculate();
})

    useEffect(() => {
        dispatch(getMeals());
        dispatch(getItems());
        console.log('items', selectedItems);
        console.log('meals', newMeal);

    }, [dispatch]);

    useEffect(() => {
        setNewMeal({...newMeal, totalCarbs: calculate(), items: [...selectedItems]});
    }, [selectedItems]);
    
    const handleNewItem = (value, index, name) => {
        if(name == 'item'){
            value = Items.find((item) => item.name == value);
        }
        let newSelectedItems = [...selectedItems];
        newSelectedItems[index][name] = value;
        setSelectedItems(newSelectedItems);
    }

    const handleDeleteItem = (index) => {
        let newSelectedItems = [...selectedItems];
        newSelectedItems.splice(index,1);
        setSelectedItems(newSelectedItems);
    }

    const handleNewMealChange = (e) => {
        const value = e.target.value;
        setNewMeal({...newMeal, [e.target.name]: value});

    }

    const calculate = () => {
        let totalCarbs = 0;
        if(newMeal.totalWeight > 0 && selectedItems.length > 0){
            selectedItems.forEach(item => {
                if(item.amount > 0){
                totalCarbs += item.amount*item.item.carbs;
                }
            });
            const val = (totalCarbs/newMeal.totalWeight);
            setTotalCarbs(val);
            
            return val
        }
        //setTotalWeight('Insert a weight and select items');
        
    }

    const handleSubmit = async () => {
        //CREATE VALIDAIOTN FOR FORM
        console.log(selectedItems)
        await setNewMeal({...newMeal, totalCarbs: "hello", items: [...selectedItems]})
        console.log(newMeal)
        
        if(newMeal.name.length > 0 && newMeal.description.length > 0 && newMeal.totalWeight > 0){
            dispatch(createMeal(newMeal));
            setNewMeal({name: '', description: '', totalWeight: '', carbs: 0});
            setSelectedItems([testsampleItem]);
            setOpen(false);
        }
    }

    const handleSearchChange = (e) => {
        e.target.value.length > 0 ? dispatch(searchMeals(e.target.value)) : dispatch(getMeals());
    }

    return (
        <Container>
        <Backdrop open={open} style={{zIndex: '1'}}>
            <Paper style={{width: '', minHeight: '',padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '20px'}}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px'}} >
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '10px'}} >
                        <TextField name='name' onChange={handleNewMealChange}style={{width: '180px'}} placeholder='Name'/>
                        <TextField name='description'  onChange={handleNewMealChange} style={{width: '180px'}} multiline placeholder='Description' />
                        <TextField name='totalWeight' onChange={handleNewMealChange} style={{width: '180px'}} placeholder='Total Weight' InputProps={{endAdornment: <InputAdornment position='start' >g</InputAdornment>}}/>
                    </div>
                    <div>
                        {selectedItems.map((item, index) => (
                            
                            <div style={{display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center'}} >
                            {selectedItems.length > 1 &&  <IconButton onClick={() => handleDeleteItem(index)} ><DeleteIcon /></IconButton>}
                        
                            <Autocomplete onChange={(event, newValue) => handleNewItem(newValue, index, 'item')} disablePortal options={Items.map((item) => item.name)} renderInput={(params) => <TextField {...params} style={{width: '200px'}} label="Item" />}/>
                            <TextField onChange={(e) => handleNewItem(e.target.value, index, 'amount')} style={{width: '80px'}} label="amount" InputProps={{endAdornment: <InputAdornment position='start' >g</InputAdornment>}}/>
                            {index == selectedItems.length - 1 && <IconButton onClick={() => {setSelectedItems([...selectedItems, testsampleItem])}}><AddIcon /></IconButton>}
                            </div>
                        ))}
                    </div>
                </div>
                <Divider style={{width: '100%'}}/>
                <div>
                    <div>
                    <Typography variant='h6'>Carbs: <b>{TotalCarbs*100}</b>%</Typography>
                    </div>
                    <div style={{display: 'flex' , gap: '20px'}} >
                    <Button variant='contained' color='primary' onClick={() => handleSubmit()} >Finish</Button>
                    <Button onClick={()=> setOpen(false)} >Cancel</Button>
                    </div>
                </div>
            </Paper>
        </Backdrop>
        <div style={{display: 'flex', rowGap: '20px', flexDirection: 'column'}} >
        <div style={{display: 'grid', justifyContent: 'center' }} >
            <Typography variant="h4" style={{marginTop: '20px', opacity: '0.7'}}><b>Created Meals</b></Typography>
            <Divider style={{marginLeft: '4.5%', marginRight: '4.5%', width: '100%', background: 'linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(0,0,1,1) 50%, rgba(0,212,255,0) 100%)'}} ></Divider>
        </div>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <SearchField onChangee={handleSearchChange} />
        </div>
        <div>
        {!meals.length ? <Button onClick={() => {setOpen(!open)}} style={{position: 'fixed', bottom: '20px', right: '20px', borderRadius: '20px', backgroundColor: 'white'}} variant='contained' >Add Meal<AddCircleIcon style={{marginLeft: '5px'}} /></Button> : (
            <Grid container alignItems="stretch" spacing={3} style={{width: '100%'}}>
                
                {meals.map((meal) => (
                    <Grid key={meal._id} item xs={12} sm={4} style={{position: 'relative', width: '100%'}} >
                        <Meal meal={meal}/>
                    </Grid>
                ))}
            <Button onClick={() => {setOpen(!open)}} style={{position: 'fixed', bottom: '20px', right: '20px', borderRadius: '20px', backgroundColor: 'white'}} variant='contained' >Add Meal<AddCircleIcon style={{marginLeft: '5px'}} /></Button>
            </Grid>
    )}
    </div>
    </div>
    </Container>
    )
}

export default Food;