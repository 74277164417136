import {CREATE_ITEM, GET_ITEMS, DELETE_ITEM} from '../constants/actionTypes';
export default (items = [], action) => {
    switch(action.type){
        case GET_ITEMS:
            return action.payload;
        case CREATE_ITEM:
            return [... items, action.payload];
        case DELETE_ITEM:
            return items.filter(item => item._id !== action.payload._id);

        default:
            return items;
    }

}