import {CREATE_MEAL, GET_MEALS} from '../constants/actionTypes';
export default (meals = [], action) => {
    switch(action.type){
        case GET_MEALS:
            return action.payload;
        case CREATE_MEAL:
            return [... meals, action.payload];

        default:
            return meals;
    }

}