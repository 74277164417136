import {GET_ITEMS, CREATE_ITEM, DELETE_ITEM} from '../constants/actionTypes';
import * as api from '../api';


// Action Creators
export const getItems = () => async (dispatch) => {

    try {
        const {data} = await api.getItems();
        dispatch({type: GET_ITEMS, payload: data});
    } catch (error) {
        console.log(error.message);
    }   
}

export const createItem = (item) => async (dispatch) => {
    try {
        const req = await api.createItem(item);
        dispatch({ type: CREATE_ITEM, payload: req.data})
        return req;
    } catch (error) {
        //console.log(error.response.data.message)
        return error;
    }
}

export const searchItems = (query) => async (dispatch) => {
    try {
        const {data} = await api.searchItems(query);
        dispatch({ type: GET_ITEMS, payload: data});
    } catch (error) {
        console.log(error.message);
    }
}


export const deleteItem = (id) => async (dispatch) => {
    try {
        const {data} = await api.deleteItem(id);
        dispatch({ type: DELETE_ITEM, payload: data});
    } catch (error) {
        console.log(error.message);
    }
}